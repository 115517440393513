import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";

import { useTranslation } from "react-i18next";

import FileUploadWithPreview from "file-upload-with-preview";
import { Box, Button, Link, Flex, Text } from "rebass";
import { Checkbox, Label, Select } from "@rebass/forms";
import { Input } from "./Input.css";
import { Textarea } from "./Textarea.css";
import Overlay from "./Overlay";
import { FiDownload } from "react-icons/fi";
import PartForm from "./PartForm";

import styled from "styled-components";
import Dimensions from "./Dimensions";
import FileViewer from "./FileViewer";
import AuthContext from "./AuthContext";
import FileUpload from "./FileUpload";
import { StyledModal } from "./StyledModal.css";

let upload = null;

const ErrorText = styled.span`
  color: red;
`;

const formReducer = (state, { parent, field, value, index }) => {
  if (parent) {
    return { ...state, [parent]: { ...state[parent], [field]: value } };
  }
  if (field === "part") {
    const existingParts = [...state.parts] || [];
    if (index === existingParts.length) {
      existingParts.push(value);
    } else {
      existingParts[index] = value;
    }
    return { ...state, parts: existingParts };
  } else if (field === "deletePart") {
    const existingParts = [...state.parts] || [];
    existingParts.splice(index, 1);
    return { ...state, parts: existingParts };
  } else {
    return { ...state, [field]: value };
  }
};

const compareFields = (object1, object2, fields) => {
  for (let field of fields) {
    if (JSON.stringify(object1[field]) !== JSON.stringify(object2[field])) {
      return false;
    }
  }
  return true;
};

const checkForTrue = (obj) => {
  for (let k of Object.keys(obj)) {
    if (typeof obj[k] === "object" && obj[k]) {
      if (checkForTrue(obj[k])) {
        return true;
      }
    } else if (obj[k]) {
      return true;
    }
  }
  return false;
};

const merge = (parent, child, overwrittenProperties) => {
  if (!overwrittenProperties) {
    return {};
  }

  const overwrittenValues = Object.keys(child)
    .filter((k) => Object.keys(overwrittenProperties).includes(k))
    .reduce((res, k) => {
      return { ...res, [k]: child[k] };
    }, {});

  return { ...parent, ...overwrittenValues };
};

const isEditable = (overwrittenProperties, name, type) => {
  if (type === "parent") {
    return !overwrittenProperties[name];
  } else if (type === "child") {
    return overwrittenProperties && !!overwrittenProperties[name];
  } else {
    return true;
  }
};

const filterEditable = (overwrittenProperties, object, type) => {
  if (type === "regular") {
    return object;
  }

  return Object.keys(object)
    .filter((k) => isEditable(overwrittenProperties, k, type))
    .reduce((res, key) => ({ ...res, [key]: object[key] }), {});
};

const ProductInputForm = forwardRef((props, ref) => {
  const product = props.product;
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const [parentItem, setParentItem] = useState(
    // TODO: we shouldn't allow a parent item which has children to be deleted or changed to regular or child
    product.parent && product.parent.id
      ? props.parentItems.find((i) => i.id === product.parent.id) || {}
      : {}
  );

  const [overwrittenProperties, setOverwrittenProperties] = useState(
    product.overwrittenProperties || {}
  );
  const [isOpen, setIsOpen] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [editablePartIndex, setEditablePartIndex] = useState(0);
  const [state, dispatch] = useReducer(formReducer, product);
  const [errors, setErrors] = useState([]);

  useImperativeHandle(ref, () => ({
    isDirty() {
      return !compareFields(state, product, [
        "shortName",
        "status",
        "userId",
        "code",
        "color",
        "ean",
        "externalId",
        "attachments",
        "dimensions",
        "packageDimensions",
        "note",
        "parts",
      ]);
    },
  }));

  const id = product.id;

  const validate = () => {
    let messages = [];

    if (!state.shortName) {
      messages.push("Short name is required");
    }

    if (state.type === "child" && !parentItem.id) {
      messages.push("Parent item is required");
    }

    if (state.type === "parent" && !checkForTrue(overwrittenProperties)) {
      messages.push("At least one property should be overwritten");
    }

    if (!state.code) {
      messages.push("Code is required");
    }

    if (!state.status) {
      messages.push("Status is required");
    }

    setErrors(messages);

    return !messages.length;
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const deletePart = (index) => {
    dispatch({ field: "deletePart", value: { index } });
  };

  const addPart = () => {
    setEditablePartIndex(state.parts.length);
    toggleModal();
  };

  const editPart = (index) => {
    setEditablePartIndex(index);
    toggleModal();
  };

  useEffect(() => {
    upload = new FileUploadWithPreview("upload-attachments", {
      text: {
        chooseFile: t("add_files"),
      },
    });
  }, [id, state.attachments]);

  // status update might come to the product process form
  useEffect(() => {
    dispatch({ field: "status", value: product.status });
  }, [product.status]);

  return (
    <div>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        <PartForm
          part={
            !state.parts || editablePartIndex === state.parts.length
              ? {}
              : state.parts[editablePartIndex]
          }
          onCancel={() => toggleModal()}
          onEditFinished={(part) => {
            toggleModal();
            dispatch({ field: "part", value: part, index: editablePartIndex });
          }}
        />
      </StyledModal>

      {props.loading && <Overlay />}

      <Box
        px={20}
        mt={50}
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ErrorText>{errors.join(". ")}</ErrorText>
        <Label htmlFor="shortName">{t("short_name")} *</Label>
        <Textarea
          id="shortName"
          data-cy="short-name"
          sx={{ resize: "none", height: "4em" }}
          name="name"
          my={10}
          value={state.shortName || ""}
          onChange={(e) => {
            return dispatch({ field: "shortName", value: e.target.value });
          }}
        />

        <Flex mx={-2}>
          <Box width={1 / 3} px={1}>
            <Label htmlFor="type">{t("type")}</Label>
            <Select
              my={10}
              data-cy="type"
              value={state.type}
              onChange={(e) => {
                dispatch({ field: "type", value: e.target.value });
                if (e.target.value !== "parent") {
                  setOverwrittenProperties({});
                }
              }}
            >
              {["regular", "parent", "child"].map((key) => (
                <option key={key} value={key}>
                  {t(key)}
                </option>
              ))}
            </Select>
          </Box>
          {state.type === "parent" && (
            <Box width={2 / 3} px={1}>
              <Label htmlFor="overwritten_properties">
                {t("overwritten_properties")}
              </Label>
              <Box display="flex" alignItems="center">
                <Label my={10}>
                  <Checkbox
                    id="overwrite-color"
                    data-cy="overwrite-color"
                    onChange={() => {
                      setOverwrittenProperties((currentProps) => {
                        return { ...currentProps, color: !currentProps.color };
                      });
                    }}
                    checked={overwrittenProperties.color || false}
                  />
                  {t("color")}
                </Label>
                <Label>
                  <Checkbox
                    id="overwrite-length"
                    data-cy="overwrite-length"
                    onChange={() => {
                      setOverwrittenProperties((currentProps) => {
                        return {
                          ...currentProps,
                          dimensions: {
                            length: !(currentProps.dimensions || {}).length,
                          },
                        };
                      });
                    }}
                    checked={
                      (overwrittenProperties.dimensions || {}).length || false
                    }
                  />
                  {t("length")}
                </Label>
              </Box>
            </Box>
          )}

          {state.type === "child" && (
            <Box width={2 / 3} px={1}>
              <Label htmlFor="parent_item">{t("parent_item")}</Label>
              <Select
                my={10}
                data-cy="parent"
                value={parentItem.id}
                onChange={(e) => {
                  setParentItem(
                    props.parentItems.find((i) => i.id === e.target.value) || {}
                  );
                }}
              >
                <option key={0} value="" />
                {props.parentItems.map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.shortName}
                  </option>
                ))}
              </Select>
            </Box>
          )}
        </Flex>

        <Flex mx={-2} mt={2} flexWrap="wrap">
          <Box width={[1, 1, 1 / 3]} px={1}>
            <Label htmlFor="code">{t("code")} *</Label>
            <Input
              id="code"
              data-cy="code"
              my={10}
              value={state.code || ""}
              onChange={(e) =>
                dispatch({ field: "code", value: e.target.value })
              }
            />
          </Box>

          {state.type !== "parent" && (
            <Box width={[1, 1, 1 / 3]} px={1}>
              <Label htmlFor="ean">EAN</Label>

              <Input
                my={10}
                value={state.ean || ""}
                data-cy="ean"
                onChange={(e) =>
                  dispatch({ field: "ean", value: e.target.value })
                }
              />
            </Box>
          )}
          {state.type !== "parent" && (
            <Box width={[1, 1, 1 / 3]} px={1}>
              <Label htmlFor="external-id">External Id</Label>

              <Input
                id="external-id"
                data-cy={"external-id"}
                my={10}
                value={state.externalId || ""}
                onChange={(e) =>
                  dispatch({ field: "externalId", value: e.target.value })
                }
              />
            </Box>
          )}
        </Flex>

        {user.roles.includes("admin") && (
          <Flex mx={-2}>
            <Box width={1 / 3} px={1}>
              <Label htmlFor="status">Status</Label>
              <Select
                my={10}
                data-cy="status"
                value={state.status}
                onChange={(e) => {
                  dispatch({ field: "status", value: e.target.value });
                }}
              >
                {[
                  "new",
                  "prepared",
                  "in_progress",
                  "in_review",
                  "finished",
                  "archived",
                ].map((key) => (
                  <option key={key} value={key}>
                    {t(key)}
                  </option>
                ))}
              </Select>
            </Box>
            <Box width={2 / 3} px={1}>
              <Label htmlFor="user">{t("user")}</Label>
              <Select
                my={10}
                data-cy="user-id"
                defaultValue={state.userId}
                onChange={(e) => {
                  dispatch({ field: "userId", value: e.target.value });
                }}
              >
                <option key={0} value="">
                  -
                </option>
                {props.users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.email}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
        )}

        <Label htmlFor="note">{t("note")}</Label>
        <Textarea
          data-cy="note"
          sx={{ height: "10em" }}
          value={state.type === "child" ? parentItem.note : state.note || ""}
          disabled={state.type === "child"}
          onChange={(e) => dispatch({ field: "note", value: e.target.value })}
          my={10}
        />

        <Flex mb={10} alignItems="center">
          <Box>
            <Text fontSize={[1, 2, 2]} fontWeight="bold" color="primary">
              {t("attached_files")}
            </Text>
          </Box>
          <Box flex={1}>
            {!!state.id && !!state.attachments.length && (
              <Button
                data-cy="download-attachments"
                py={1}
                mx={2}
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL +
                    "/download/" +
                    id +
                    "/attachments")
                }
              >
                <FiDownload color="white" title="Download all" />
              </Button>
            )}
          </Box>
        </Flex>

        <FileViewer
          mt={10}
          allowDelete={true}
          attachments={state.attachments}
          data-cy="attachments"
          type="attachments"
          productId={id}
          onDelete={(a) => {
            const deletedAtts = [...deletedAttachments];
            deletedAtts.push(a);

            setDeletedAttachments(deletedAtts);
          }}
        />

        <FileUpload id="upload-attachments" data-cy="upload-attachments" />

        <Text fontSize={[1, 2, 2]} fontWeight="bold" color="primary">
          {t("product_attributes")}
        </Text>

        <Flex mx={-2} mt={2}>
          <Box width={1 / 3} px={2}>
            <Label htmlFor="color">{t("color")}</Label>
            {state.type !== "parent" || !overwrittenProperties.color ? (
              <Input
                id="color"
                name="color"
                disabled={
                  state.type === "child" &&
                  (!Object.keys(parentItem).length ||
                    !parentItem.overwrittenProperties.color)
                }
                data-cy={"attributes-color"}
                my={10}
                value={
                  state.type !== "child"
                    ? state.color || ""
                    : parentItem.overwrittenProperties &&
                      parentItem.overwrittenProperties.color
                    ? state.color || ""
                    : parentItem.color || ""
                }
                onChange={(e) =>
                  dispatch({
                    field: "color",
                    value: e.target.value,
                  })
                }
              />
            ) : (
              <Input
                my={10}
                disabled={true}
                value={""}
                placeholder={t("will_be_overwritten")}
              />
            )}
          </Box>
        </Flex>

        <Dimensions
          itemType={state.type}
          overwrittenProperties={
            state.type === "child"
              ? parentItem.overwrittenProperties
                ? parentItem.overwrittenProperties.dimensions
                : {}
              : overwrittenProperties.dimensions
          }
          data-cy="dimensions"
          {...(state.type === "child"
            ? merge(
                parentItem.dimensions,
                state.dimensions || {},
                (parentItem.overwrittenProperties || {}).dimensions
              )
            : state.dimensions)}
          dispatch={({ field, value }) => {
            dispatch({ parent: "dimensions", field, value });
          }}
        />

        <Text fontSize={[1, 2, 2]} fontWeight="bold" color="primary">
          {t("package_dimensions")}
        </Text>

        <Dimensions
          data-cy="package-dimensions"
          itemType={state.type}
          {...(state.type === "child"
            ? { ...parentItem.packageDimensions }
            : state.packageDimensions)}
          dispatch={({ field, value }) => {
            dispatch({ parent: "packageDimensions", field, value });
          }}
        />

        <Flex mb={2} mt={4}>
          <Text
            fontSize={[1, 2, 2]}
            my="auto"
            fontWeight="bold"
            color="primary"
          >
            {t("parts")}
          </Text>
          <Box flex={1} />

          {state.type !== "child" && (
            <Button data-cy="add-part" mr={2} onClick={() => addPart()}>
              {t("add")}
            </Button>
          )}
        </Flex>
        <Box style={{ border: "1px solid" }} px={20} py={20}>
          {(!state.parts || !state.parts.length) && <Text>No Parts Added</Text>}

          {state.parts && !!state.parts.length && (
            <Flex sx={{ borderBottom: "2px solid" }}>
              <Box width={5 / 6}>Name</Box>
              <Box width={1 / 6} />
            </Flex>
          )}

          {(state.parts || []).map((part, index) => (
            <Flex sx={{ borderBottom: "1px solid" }} key={index} my={2}>
              <Box width={5 / 6} my={2}>
                <strong>{part.name}</strong>
                <Box>
                  {part.attributes &&
                    Object.keys(part.attributes)
                      .map((k) => k + ": " + part.attributes[k])
                      .join(", ")}
                </Box>
              </Box>

              {state.type !== "child" && (
                <Box width={1 / 6}>
                  <Link href={"#"} onClick={() => editPart(index)}>
                    Edit
                  </Link>{" "}
                  <Link
                    href={"#"}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        deletePart(index);
                      }
                    }}
                  >
                    Delete
                  </Link>
                </Box>
              )}
            </Flex>
          ))}
        </Box>
        <Button
          mt={10}
          data-cy="save-product-input"
          onClick={() => {
            if (validate()) {
              const variables = {
                code: state.code,
                shortName: state.shortName,
                status: state.status,
                userId: state.userId,
                note: state.note,
                type: state.type,
                attachments: upload.cachedFileArray,
                deletedAttachments,
              };

              if (state.type !== "parent") {
                variables.ean = state.ean;
                variables.externalId = state.externalId;
              }

              if (state.type !== "child") {
                variables.packageDimensions = state.packageDimensions;
                variables.parts = state.parts;
              }

              if (state.type === "parent") {
                variables.overwrittenProperties = overwrittenProperties;
              }

              if (state.type === "child") {
                variables.parentId = parentItem.id;
              }

              if (id) {
                variables.id = id;
              }

              if (
                isEditable(
                  state.type === "child"
                    ? parentItem.overwrittenProperties
                    : overwrittenProperties,
                  "color",
                  state.type
                )
              ) {
                variables.color = state.color;
              }

              variables.dimensions = filterEditable(
                (state.type === "child"
                  ? parentItem.overwrittenProperties
                  : overwrittenProperties
                ).dimensions,
                state.dimensions || {},
                state.type
              );

              props
                .mutation({
                  variables,
                })
                .then(() => {
                  props.onSaveCompleted();
                })
                .catch((e) => setErrors([e.message]));
            }
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </div>
  );
});

export default ProductInputForm;
