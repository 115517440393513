import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { Box, Button, Flex, Link } from "rebass";
import { Link as ReactLink } from "react-router-dom";

import {
  FiChevronUp,
  FiChevronDown,
  FiEdit,
  FiEye,
  FiTrash2,
} from "react-icons/fi";

import { useTranslation } from "react-i18next";
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useRowSelect,
} from "react-table";
import { useMutation } from "@apollo/client";
import {
  DELETE_PRODUCT,
  UPDATE_PRODUCTS_STATUS,
  UPDATE_PRODUCTS_USER,
} from "../gql/mutations";
import { GET_PRODUCTS } from "../gql/queries";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import AuthContext from "./AuthContext";
import { Checkbox, Label, Select } from "@rebass/forms";
import MassActionModal from "./MassActionModal";

const PAGE_SIZE = 100;

const Styles = styled.div`
  padding: 1rem;
  ${"" /* These styles are suggested for the table fill all available space in its containing element */}
  display: block;
  ${"" /* These styles are required for a horizontaly scrollable table overflow */}
  overflow: auto;

  .table {
    border-spacing: 0;
    width: 100%;

    .thead {
      ${"" /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;
    }

    .table {
    }

    .tr {
      :first-child {
        .th {
          border-top: 1px solid black;
        }
      }
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid black;
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${"" /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      :first-child {
        border-left: 1px solid black;
      }

      :last-child {
        border-right: 1px solid black;
      }
    }
  }
`;

const parseDate = (dateString) => {
  const parts = dateString.split(".");
  return new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10)
  );
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function ProductTable(props) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [selectedAction, setSelectedAction] = useState("");

  function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    );
  }

  // This is a custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows

    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  const [updateProductsStatus] = useMutation(UPDATE_PRODUCTS_STATUS, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: { includeArchived: props.showArchivedProducts },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [updateProductsUser] = useMutation(UPDATE_PRODUCTS_USER, {
    refetchQueries: [
      {
        query: GET_PRODUCTS,
        variables: { includeArchived: props.showArchivedProducts },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCTS }],
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Products",
        columns: [
          {
            Header: t("short_name"),
            accessor: "shortName",
            width: 400,
            Filter: DefaultColumnFilter,
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id].toLowerCase()).startsWith(
                    filter.value.toLowerCase()
                  )
                : true;
            },
          },
          {
            Header: t("code"),
            accessor: "code",
            width: 150,
            Filter: DefaultColumnFilter,
            filterMethod: (filter, row) => {
              const id = filter.pivotId || filter.id;
              return row[id] !== undefined
                ? String(row[id].toLowerCase()).startsWith(
                    filter.value.toLowerCase()
                  )
                : true;
            },
          },
          {
            Header: "Status",
            accessor: "status",
            width: 100,
            Filter: SelectColumnFilter,
            filter: "equals",
          },
          {
            Header: t("user"),
            Filter: SelectColumnFilter,
            filter: "equals",
            width: 200,
            accessor: (p) => (p.user ? p.user.email : "-"),
          },
          {
            Header: t("created_at"),
            accessor: (p) => new Date(p.createdAt).toLocaleDateString("ro-RO"),
            width: 100,
            sortType: (row1, row2, columnName) => {
              return parseDate(row1.values[columnName]) <
                parseDate(row2.values[columnName])
                ? 1
                : -1;
            },
          },
          {
            Header: t("updated_at"),
            accessor: (p) => new Date(p.updatedAt).toLocaleDateString("ro-RO"),
            sortType: (row1, row2, columnName, dir) => {
              return parseDate(row1.values[columnName]) <
                parseDate(row2.values[columnName])
                ? 1
                : -1;
            },
            width: 100,
          },

          {
            id: "actions",
            Header: t("actions"),
            width: 120,
            Cell: ({ row }) => {
              if (!props.data || !props.data.length) {
                return null;
              }

              const product = props.data[row.id];

              return (
                <div>
                  <Link
                    mr={2}
                    data-cy="view"
                    to={{
                      pathname: "/products/" + product.id,
                      data: product,
                    }}
                    as={ReactLink}
                  >
                    <FiEye title="View Product" size={20} />
                  </Link>
                  {"  "}

                  {(user.roles.includes("admin") ||
                    product.user.id === user.id) && (
                    <Link
                      data-cy="edit"
                      mx={1}
                      to={{
                        pathname: "/products/" + product.id + "/edit",
                        data: product,
                      }}
                      as={ReactLink}
                    >
                      <FiEdit title="Edit Product" size={20} />
                    </Link>
                  )}
                  {"  "}

                  {user.roles.includes("admin") && (
                    <Link
                      data-cy="delete"
                      ml={2}
                      href={"#"}
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          deleteProduct({ variables: { id: product.id } });
                        }
                      }}
                    >
                      <FiTrash2 title="Delete product?" size={20} />
                    </Link>
                  )}
                </div>
              );
            },
          },
        ],
      },
    ],
    [props.data, deleteProduct, user.roles]
  );

  const {
    getTableProps,
    headerGroups,
    canPreviousPage,
    canNextPage,
    nextPage,
    toggleAllRowsSelected,
    page,
    preFilteredRows,
    previousPage,
    prepareRow,
    selectedFlatRows,
    rows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data: props.data,
      initialState: {
        pageIndex: 0,
        pageSize: PAGE_SIZE,
        sortBy: [
          {
            id: "createdAt",
            desc: true,
          },
        ],
      },
      pageCount: Math.ceil(props.data.length / PAGE_SIZE),
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const actions = {
    changeStatus: "Change Status ...",
  };

  if (user.roles.includes("admin")) {
    actions.changeUser = "Change User ...";
  }
  async function changeProductsAssignee(userId) {
    await updateProductsUser({
      variables: {
        ids: selectedFlatRows.map((r) => r.original.id),
        userId: userId,
      },
    });
    setSelectedAction("");
    toggleAllRowsSelected(false);
  }

  async function changeProductsStatus(status) {
    await updateProductsStatus({
      variables: {
        ids: selectedFlatRows.map((r) => r.original.id),
        status: status,
      },
    });
    setSelectedAction("");
    toggleAllRowsSelected(false);
  }

  const headerGroup = headerGroups[1];

  const statusOptions = useMemo(() => {
    const statuses = ["new", "prepared"];

    if (user.roles.includes("admin")) {
      statuses.push("in_progress", "in_review", "finished", "archived");
    }

    return statuses.map((s) => ({ id: s, name: t(s) }));
  }, [preFilteredRows]);

  const usersOptions = useMemo(() => {
    const options = [];
    props.data.forEach((row) => {
      const user = row.user;

      if (!options.find((o) => o.id === user.id)) {
        options.push({ id: user.id, name: row.user.email });
      }
    });
    return [...options.values()];
  }, [props.data]);

  return (
    <Styles>
      {selectedAction === "changeStatus" && (
        <MassActionModal
          title={`Change status of ${selectedFlatRows.length} product(s)`}
          options={statusOptions}
          okClicked={(status) => changeProductsStatus(status)}
          cancelClicked={() => {
            setSelectedAction("");
          }}
        />
      )}

      {selectedAction === "changeUser" && (
        <MassActionModal
          title={`Assign ${selectedFlatRows.length} products to`}
          options={usersOptions}
          okClicked={(userId) => changeProductsAssignee(userId)}
          cancelClicked={() => {
            setSelectedAction("");
          }}
        />
      )}

      {(user.roles.includes("dataEntry") || user.roles.includes("admin")) && (
        <Flex mb={[2]} justifyContent={"space-between"} alignItems={"center"}>
          <Flex width={[3 / 4, 1 / 2]} alignItems={"center"}>
            <Box width={[1, 1 / 3]}>
              <Select
                value={selectedAction}
                disabled={!selectedFlatRows.length}
                id="actions"
                name="actions"
                onChange={(e) => {
                  setSelectedAction(e.target.value);
                }}
              >
                <option>Actions</option>
                {Object.entries(actions).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
            </Box>
            <Box width={[1, 1 / 3]} mx={2}>
              {Object.keys(selectedRowIds).length
                ? Object.keys(selectedRowIds).length + " Selected"
                : ""}
            </Box>
            <Box>
              <Label>
                <Checkbox
                  id="show-archived"
                  name="show-archived"
                  checked={props.showArchivedProducts}
                  onChange={() =>
                    props.setShowArchivedProducts(!props.showArchivedProducts)
                  }
                />
                {t("show_archived_products")}
              </Label>
            </Box>
          </Flex>

          <Box>
            <Button
              variant="primary"
              data-cy="add-product"
              mr={2}
              onClick={() => props.history.push("/products/new")}
            >
              {t("add")}
            </Button>
          </Box>
        </Flex>
      )}

      <Flex>
        <div className="table" {...getTableProps()}>
          <div
            {...headerGroup.getHeaderGroupProps()}
            key={"names"}
            className="tr"
          >
            {headerGroup.headers.map((column) => (
              <div
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="th"
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FiChevronUp />
                    ) : (
                      <FiChevronDown />
                    )
                  ) : (
                    ""
                  )}
                </span>
              </div>
            ))}
          </div>
          <div
            {...headerGroup.getHeaderGroupProps()}
            key={"filters"}
            className="tr"
          >
            {headerGroup.headers.map((column) => (
              <div {...column.getHeaderProps()} className="th">
                <div>
                  {column.filter || column.filterMethod
                    ? column.render("Filter")
                    : null}
                </div>
              </div>
            ))}
          </div>

          {page.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Flex>
      <Flex mt={24} className="pagination">
        <Box flex={1}>
          {canPreviousPage && (
            <Button onClick={() => previousPage()}>Previous</Button>
          )}
        </Box>
        <Box flex={4} textAlign="center">
          {pageIndex * pageSize} -{" "}
          {Math.min(rows.length, (pageIndex + 1) * pageSize)} of {rows.length}
        </Box>
        <Box flex={1} textAlign="right">
          {canNextPage && (
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          )}
        </Box>
      </Flex>
    </Styles>
  );
}

export default ProductTable;
