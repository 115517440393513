import Modal from "styled-react-modal";

export const StyledModal = Modal.styled`  
  @media screen (min-width:40em) {
    height: 20em;
    width: 20em;
  }
  width: 40em;
  height: 40em;
  margin-left: 20px;
  margin-right: 20px;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
