import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER, GET_USERS } from "../gql/queries";
import { Box, Text } from "rebass";
import { UPDATE_USER } from "../gql/mutations";
import UserForm from "../components/UserForm";

const User = (props) => {
  const id = props.match.params.id;

  const { loading, data } = useQuery(GET_USER, {
    variables: {
      id: id,
    },
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      { query: GET_USERS },
      { query: GET_USER, variables: { id } },
    ],
    awaitRefetchQueries: true,
  });

  if (loading) {
    return null;
  }

  return (
    <Box
      width={[1, 1 / 2]}
      mx="auto"
      px={10}
      mt={50}
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Text fontSize={[1, 2, 2]} fontWeight="bold" color="primary">
        Update User
      </Text>
      <UserForm
        user={data.user}
        onSave={(u) => {
          updateUser({ variables: { ...u } }).then(
            props.history.push(`/users`)
          );
        }}
      />
    </Box>
  );
};

export default User;
