import ReactDOM from "react-dom";
import React from "react";

import { Box, Flex, Heading } from "rebass";

const overlayRoot = document.getElementById("overlay-root");

const Overlay = () => {
  return ReactDOM.createPortal(
    <Flex
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        height: "100vh",
        width: "100vw",
        zIndex: 999,
      }}
    >
      <Box
        mx="auto"
        my="auto"
        textAlign="center"
        width={[1, 1, 1 / 2, 1 / 4]}
        style={{
          height: "30em",
        }}
      >
        <Heading mt={50} mb={30} color="#9e9e9e">
          Please wait
        </Heading>
        <img alt="loading" src="/loading.svg" />
      </Box>
    </Flex>,
    overlayRoot
  );
};

export default Overlay;
