import { Checkbox, Input, Label } from "@rebass/forms";
import { Box, Button } from "rebass";
import React, { useState } from "react";
import { SelectMultiple } from "./SelectMultiple";
import ErrorText from "./ErrorText";
import constants from "../constants";
const UserForm = (props) => {
  const user = props.user;
  const [email, setEmail] = useState(user.email || "");
  const [active, setActive] = useState(user.active || true);
  const [errors, setErrors] = useState([]);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [roles, setRoles] = useState(user.roles || []);

  const validate = () => {
    let messages = [];

    if (!email) {
      messages.push("Email is required");
    }

    if (!user.id && !password) {
      messages.push("Password is required");
    }

    if (!user.id && password !== verifyPassword) {
      messages.push("Passwords don't match");
    }

    if (!roles.length) {
      messages.push("At least one role is required.");
    }

    setErrors(messages);

    return !messages.length;
  };

  return (
    <Box mt={20}>
      <ErrorText>{errors.join(". ")}</ErrorText>
      <Label htmlFor="email">Email *</Label>
      <Input
        autoComplete="off"
        data-cy="email"
        id="email"
        my={10}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {!props.user.id && (
        <div>
          <Label htmlFor="password">Password *</Label>
          <Input
            autoComplete="off"
            data-cy="password"
            id="password"
            type="password"
            my={10}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Label htmlFor="verify-password">Verify Password * </Label>
          <Input
            autoComplete="off"
            id="verify-password"
            name="verify-password"
            data-cy="verify-password"
            type="password"
            my={10}
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
          />
        </div>
      )}
      <Box>
        <Label htmlFor="roles">Roles *</Label>
        <SelectMultiple
          defaultValue={user.roles}
          id="roles"
          data-cy="roles"
          multiple
          values={user.roles}
          onChange={(e) => {
            setRoles([...e.target.selectedOptions].map((o) => o.value));
          }}
        >
          {Object.entries(constants.roles).map(([key, role]) => (
            <option key={key} value={key}>
              {role}
            </option>
          ))}
        </SelectMultiple>
      </Box>
      <Label mt={10}>
        <Checkbox
          id="active"
          name="active"
          onChange={() => {
            setActive(!active);
          }}
          checked={active}
        />
        Active
      </Label>
      <Button
        data-cy="save-user"
        mt={10}
        onClick={() => {
          if (validate()) {
            let u = { email, active, roles };
            if (!user.id) {
              u.password = password;
            } else {
              u.id = user.id;
            }

            props.onSave(u);
          }
        }}
      >
        Save
      </Button>
    </Box>
  );
};

export default UserForm;
