import React from "react";

import { GET_USERS } from "../gql/queries";
import { useQuery } from "@apollo/client";

import { Box } from "rebass";

import UserTable from "../components/UserTable";

const Users = (props) => {
  let { loading, data } = useQuery(GET_USERS);

  return (
    <div>
      {loading && <p> loading</p>}
      <Box width={[1, 1, 1, 1 / 2]} mx="auto">
        {!loading && <UserTable data={data.users} history={props.history} />}
      </Box>
    </div>
  );
};

export default Users;
