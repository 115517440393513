import React, { useContext, useEffect, useRef } from "react";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import ProductInputForm from "../components/ProductInputForm";
import { GET_PRODUCTS, GET_USERS, GET_PARENT_PRODUCTS } from "../gql/queries";
import { useMutation, useQuery } from "@apollo/client";
import { NEW_PRODUCT } from "../gql/mutations";
import { Box } from "rebass";
import AuthContext from "../components/AuthContext";

let unblock = undefined;

const NewProduct = (props) => {
  let { loading: loadingUsers, data: usersData } = useQuery(GET_USERS);
  let { loading: loadingParentProducts, data: parentProductsData } = useQuery(
    GET_PARENT_PRODUCTS
  );

  const isDirty = () => {
    return inputFormRef.current && inputFormRef.current.isDirty();
  };

  const saveInputCompleted = () => {
    unblock();
    props.history.push(`/products`);
  };

  const inputFormRef = useRef();

  useEffect(() => {
    unblock = props.history.block(() => {
      if (isDirty()) {
        return "Are you sure you want to leave the page? There are unsaved changes";
      }
    });
    window.onbeforeunload = function (event) {
      if (isDirty()) {
        event.preventDefault();
      }
    };
    return () => {
      unblock();
      window.onbeforeunload = null;
    };
  }, [props.history]);

  const [createProduct, { loading }] = useMutation(NEW_PRODUCT, {
    refetchQueries: [{ query: GET_PRODUCTS }, { query: GET_PARENT_PRODUCTS }],
    awaitRefetchQueries: true,
  });

  const { user } = useContext(AuthContext);

  const userId = user.id;

  return (
    !loadingUsers &&
    !loadingParentProducts && (
      <Box width={[1, 1, 1 / 2, 1 / 3]} mx="auto">
        <ProductInputForm
          ref={inputFormRef}
          product={{
            attachments: [],
            parts: [],
            status: "new",
            type: "regular",
            userId,
          }}
          users={usersData.users}
          parentItems={parentProductsData.parentProducts}
          onSaveCompleted={saveInputCompleted}
          mutation={createProduct}
          loading={loading}
        />
      </Box>
    )
  );
};

export default NewProduct;
