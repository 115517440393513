import merge from "lodash.merge";
import preset from "@rebass/preset";

export default merge(preset, {
  // colors: {
  //   // custom primary color
  //   primary: "tomato",
  // },

  breakpoints: ["40em", "52em", "64em"],
  buttons: {
    primary: {
      "&:active": {
        transform: "translateY(2px)",
      },
    },
  },
});
