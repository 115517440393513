import React, { useState } from "react";

import theme from "./theme";

import { BrowserRouter } from "react-router-dom";
import Pages from "./pages";
import { ThemeProvider } from "emotion-theming";

import { setContext } from "apollo-link-context";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { ModalProvider } from "styled-react-modal";

import { createUploadLink } from "apollo-upload-client";
import Header from "./components/Header";
import AuthContext from "./components/AuthContext";
import { getCurrentUser } from "./utils";

const cache = new InMemoryCache();
const uri = process.env.REACT_APP_API_URL + "/api";
const uploadLink = createUploadLink({ uri });

// return the headers to the context
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: sessionStorage.getItem("token") || "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache,
  resolvers: {},
  connectToDevTools: true,
});
function App() {
  const [user, setUser] = useState(getCurrentUser());

  return (
    <div>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AuthContext.Provider value={{ user: user, setUser: setUser }}>
            <ModalProvider>
              <BrowserRouter>
                <Header />
                <Pages />
              </BrowserRouter>
            </ModalProvider>
          </AuthContext.Provider>
        </ThemeProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
