import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { FiDownload } from "react-icons/fi";
import FileViewer from "./FileViewer";
import { useTranslation } from "react-i18next";

const ProductProcessView = (props) => {
  const product = props.product;
  const { t } = useTranslation();

  return (
    <Box>
      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold">{t("name")}</Text>
      </Box>
      <Box my={10}>{product.name}</Box>
      <Flex alignItems="center" mb={10} mt="2em">
        <Box>
          <Text fontSize={[1, 1, 2]} fontWeight="bold">
            {t("processed_images")}
          </Text>
        </Box>
        <Box flex={1}>
          {!!product.images.length && (
            <Button
              py={1}
              mx={2}
              onClick={() =>
                (window.location.href =
                  process.env.REACT_APP_API_URL +
                  "/download/" +
                  product.id +
                  "/images")
              }
            >
              <FiDownload title="Download all" />
            </Button>
          )}
        </Box>
      </Flex>
      {!!product.images && !!product.images.length && (
        <FileViewer
          type="images"
          data-cy="images"
          mt={10}
          attachments={product.images || []}
          productId={product.id}
        />
      )}{" "}
      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold">{t("description")}</Text>
      </Box>
      <Box sx={{ whiteSpace: "pre-wrap" }} mb={4}>
        {product.type === "child"
          ? product.parent.description
          : product.description}
      </Box>
      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold">{t("specifications")}</Text>
      </Box>
      <Box sx={{ whiteSpace: "pre-wrap" }} mb={4}>
        {product.specifications}
      </Box>
    </Box>
  );
};

export default ProductProcessView;
