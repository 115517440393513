import React from "react";
import Downshift from "downshift";
import { Input } from "@rebass/forms";
import { Box, Flex } from "rebass";
import matchSorter from "match-sorter";

import { Item, Menu } from "./ComboBox.css";

const SVG = ({ size = 32, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
  />
);

const DownArrow = (props) => (
  <SVG {...props}>
    <path d="M7 10l5 5 5-5z" />
  </SVG>
);

const ComboBox = ({ "data-cy": dataCy, ...props }) => {
  function getStringItems(filter) {
    return matchSorter(props.items, filter, { keys: ["value"] }).map(
      ({ value }) => value
    );
  }

  const handleStateChange = (changes) => {
    if (changes.hasOwnProperty("selectedItem")) {
      props.onChange(changes.selectedItem);
    } else if (changes.hasOwnProperty("inputValue")) {
      props.onChange(changes.inputValue);
    }
  };

  return (
    <Downshift selectedItem={props.value} onStateChange={handleStateChange}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        getToggleButtonProps,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => (
        <div>
          <Flex sx={{ border: "1px solid", borderRadius: "default" }}>
            <Input
              data-cy={dataCy}
              sx={{ border: "0" }}
              {...getInputProps({
                isOpen,
                placeholder: "name *",
              })}
            />

            <DownArrow {...getToggleButtonProps()} />
          </Flex>
          <Menu {...getMenuProps({ isOpen })}>
            {isOpen
              ? getStringItems(inputValue).map((item, index) => (
                  <Item
                    key={index}
                    {...getItemProps({
                      item,
                      index,
                      isActive: highlightedIndex === index,
                      isSelected: selectedItem === item,
                    })}
                  >
                    {item}
                  </Item>
                ))
              : null}
          </Menu>
        </div>
      )}
    </Downshift>
  );
};

export default ComboBox;
