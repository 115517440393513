import { gql } from "@apollo/client";

const SIGNIN_USER = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id)
  }
`;

// TODO why can't we simplify this to newProduct(name: String!)
const NEW_PRODUCT = gql`
  mutation newProduct(
    $code: String!
    $ean: String
    $color: String
    $externalId: String
    $shortName: String!
    $status: String!
    $userId: String
    $parentId: String
    $dimensions: DimensionsInput
    $packageDimensions: DimensionsInput
    $deletedAttachments: [String]
    $attachments: [Upload]
    $note: String
    $type: String
    $overwrittenProperties: JSON
    $parts: [PartInput]
  ) {
    newProduct(
      code: $code
      ean: $ean
      externalId: $externalId
      shortName: $shortName
      note: $note
      color: $color
      status: $status
      userId: $userId
      parentId: $parentId
      attachments: $attachments
      dimensions: $dimensions
      packageDimensions: $packageDimensions
      deletedAttachments: $deletedAttachments
      parts: $parts
      type: $type
      overwrittenProperties: $overwrittenProperties
    ) {
      id
    }
  }
`;

const NEW_USER = gql`
  mutation newUser(
    $email: String!
    $password: String!
    $active: Boolean!
    $roles: [String!]!
  ) {
    newUser(
      email: $email
      password: $password
      active: $active
      roles: $roles
    ) {
      id
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $active: Boolean!, $roles: [String!]!) {
    updateUser(id: $id, active: $active, roles: $roles) {
      id
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($id: ID!, $password: String!) {
    changePassword(id: $id, password: $password) {
      id
    }
  }
`;

const UPDATE_CONTENT_AND_IMAGES = gql`
  mutation updateContentAndImages(
    $id: ID!
    $name: String!
    $ready: Boolean
    $deletedImages: [String]
    $images: [Upload]
    $description: String
    $specifications: String
  ) {
    updateContentAndImages(
      id: $id
      name: $name
      ready: $ready
      images: $images
      description: $description
      specifications: $specifications
      deletedImages: $deletedImages
    ) {
      id
    }
  }
`;

const UPDATE_PRODUCTS_STATUS = gql`
  mutation updateProductsStatus($ids: [ID]!, $status: String) {
    updateProductsStatus(ids: $ids, status: $status)
  }
`;

const UPDATE_PRODUCTS_USER = gql`
  mutation updateProductsUser($ids: [ID]!, $userId: ID!) {
    updateProductsUser(ids: $ids, userId: $userId)
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $code: String!
    $ean: String
    $externalId: String
    $shortName: String!
    $color: String
    $status: String!
    $userId: String
    $parentId: String
    $dimensions: DimensionsInput
    $packageDimensions: DimensionsInput
    $deletedAttachments: [String]
    $attachments: [Upload]
    $note: String
    $parts: [PartInput]
    $type: String
    $overwrittenProperties: JSON
  ) {
    updateProduct(
      id: $id
      code: $code
      color: $color
      ean: $ean
      externalId: $externalId
      shortName: $shortName
      note: $note
      status: $status
      userId: $userId
      parentId: $parentId
      attachments: $attachments
      dimensions: $dimensions
      packageDimensions: $packageDimensions
      deletedAttachments: $deletedAttachments
      parts: $parts
      type: $type
      overwrittenProperties: $overwrittenProperties
    ) {
      id
    }
  }
`;

export {
  NEW_PRODUCT,
  NEW_USER,
  CHANGE_PASSWORD,
  SIGNIN_USER,
  DELETE_PRODUCT,
  DELETE_USER,
  UPDATE_PRODUCT,
  UPDATE_CONTENT_AND_IMAGES,
  UPDATE_USER,
  UPDATE_PRODUCTS_STATUS,
  UPDATE_PRODUCTS_USER,
};
