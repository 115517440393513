import React, { forwardRef } from "react";
import { Box, Flex } from "rebass";

export const SelectMultiple = forwardRef(
  ({ "data-cy": dataCy, ...rest }, ref) => {
    return (
      <Flex {...rest}>
        <Box
          ref={ref}
          as="select"
          tx="forms"
          variant="select"
          data-cy={dataCy}
          {...rest}
          __css={{
            display: "block",
            width: "100%",
            p: 2,
            appearance: "none",
            fontSize: "inherit",
            lineHeight: "inherit",
            border: "1px solid",
            borderRadius: "default",
            color: "inherit",
            bg: "transparent",
          }}
        />
      </Flex>
    );
  }
);
