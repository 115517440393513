import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Modal from "styled-react-modal";
const gutter = 2;

const DOWNLOAD_URL = process.env.REACT_APP_S3_ENDPOINT;

const grey = "#EDEDE8";
const black = "#333";

const StyledModal = Modal.styled`  
  margin: 20px;
  .control-next.control-arrow::before {
    border-left: 8px solid gray;
}
`;

const DeleteImage = styled("span")({
  left: "0px",
  background: grey,
  position: "absolute",
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  textAlign: "center",
  display: "none",

  boxShadow: "0 4px 10px 0 rgba(black, 0.25)",
});

const FilmStrip = styled("div")({
  borderBottom: `1px solid`,
  borderTop: `1px solid}`,
  marginLeft: -gutter,
  marginRight: -gutter,
  overflowX: "auto",
  paddingBottom: 10,
  paddingTop: 10,
  WebkitOverflowScrolling: "touch",
  whiteSpace: "nowrap",
});

const Image = styled("div")({
  backgroundColor: "#eee",
  boxSizing: "border-box",
  display: "inline-block",
  margin: gutter,
  overflow: "hidden",
  paddingBottom: "15%",
  position: "relative",
  width: `calc(25% - ${gutter * 2}px)`,
  boxShadow: `0 4px 10px 0 rgba(${black}, 0.25)`,

  ":hover": {
    span: {
      display: "block",
    },
    opacity: 0.9,
  },
});

const getImages = (attachments, productId, type) => {
  return attachments.map((a) => {
    const extension = a.split(".").pop();

    let thumbnail;

    if (["jpg", "jpeg", "gif", "png"].includes(extension)) {
      thumbnail = process.env.REACT_APP_S3_ENDPOINT + "/" + productId + "/" + type + "/" + a;
    } else if (extension === "pdf") {
      thumbnail = "/pdf-success.png";
    } else {
      thumbnail = "/pdf-alt-success.png";
    }

    return {
      name: a,
      source: {
        regular: DOWNLOAD_URL + "/" + productId + "/" + type + "/" + a,
        thumbnail,
      },
    };
  });
};

const FileViewer = ({ "data-cy": dataCy, ...props }) => {
  const [images, setImages] = useState(
    getImages(props.attachments, props.productId, props.type)
  );

  const [currentModal, setCurrentModal] = useState(-1);

  return (
    <div>
      <FilmStrip data-cy={dataCy}>
        {images.map(({ caption, source }, j) => (
          <Image onClick={() => setCurrentModal(j)} key={source.regular}>
            <img
              alt={caption}
              src={source.thumbnail}
              style={{
                cursor: "pointer",
                position: "absolute",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
            {props.allowDelete && (
              <DeleteImage>
                <span
                  onClick={(e) => {
                    e.stopPropagation();

                    if (window.confirm("Delete this file?")) {
                      const imgs = [...images];
                      const deletedImage = imgs.splice(j, 1)[0];
                      setImages(imgs);
                      props.onDelete(deletedImage.name);
                    }
                  }}
                  style={{
                    marginTop: "-1px",
                    display: "block",
                    color: `darken(${grey}, 55%)`,
                  }}
                >
                  &times;
                </span>
              </DeleteImage>
            )}
          </Image>
        ))}
      </FilmStrip>
      <StyledModal
        isOpen={currentModal >= 0}
        onBackgroundClick={() => setCurrentModal(-1)}
        onEscapeKeydown={() => setCurrentModal(-1)}
      >
        <Carousel
          selectedItem={currentModal}
          dynamicHeight={true}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          width="100vh"
        >
          {images.map(({ name, source }) => (
            <div key={source.regular}>
              <img alt={name} src={source.regular} />
              <div className="legend">{name}</div>
            </div>
          ))}
        </Carousel>
      </StyledModal>
    </div>
  );
};

export default FileViewer;
