import React from "react";
import { Box, Flex } from "rebass";
import { Label } from "@rebass/forms";
import { useTranslation } from "react-i18next";
import { Input } from "./Input.css";

const Dimensions = ({ "data-cy": dataCy, ...props }) => {
  const { t } = useTranslation();
  const overwrittenProperties = props.overwrittenProperties || {};
  const itemType = props.itemType;

  return (
    <div>
      <Flex mx={-2} mt={2}>
        <Box width={1 / 3} px={2}>
          <Label htmlFor="length">{t("length")} (mm)</Label>
          {overwrittenProperties.length && itemType === "parent" ? (
            <Input
              my={10}
              disabled={true}
              value={""}
              placeholder={t("will_be_overwritten")}
            />
          ) : (
            <Input
              id="length"
              name="length"
              disabled={itemType === "child" && !overwrittenProperties.length}
              data-cy={dataCy + "-length"}
              type="number"
              my={10}
              value={props.length || ""}
              onChange={(e) =>
                props.dispatch({
                  field: "length",
                  value: parseFloat(e.target.value),
                })
              }
            />
          )}
        </Box>

        <Box width={1 / 3} px={2}>
          <Label htmlFor="width">{t("width")} (mm)</Label>

          <Input
            id="width"
            name="width"
            type="number"
            disabled={itemType === "child"}
            data-cy={dataCy + "-width"}
            my={10}
            value={props.width || ""}
            onChange={(e) =>
              props.dispatch({
                field: "width",
                value: parseFloat(e.target.value),
              })
            }
          />
        </Box>
        <Box width={1 / 3} px={2}>
          <Label htmlFor="height">{t("height")} (mm)</Label>

          <Input
            id="height"
            name="height"
            type="number"
            disabled={itemType === "child"}
            data-cy={dataCy + "-height"}
            my={10}
            value={props.height || ""}
            onChange={(e) =>
              props.dispatch({
                field: "height",
                value: parseFloat(e.target.value),
              })
            }
          />
        </Box>
      </Flex>
      <Flex mx={-2} mt={2}>
        <Box width={1 / 3} px={2}>
          <Label htmlFor="weight">{t("weight")} (gr)</Label>
          <Input
            id="weight"
            name="weight"
            disabled={itemType === "child"}
            data-cy={dataCy + "-weight"}
            type="number"
            my={10}
            value={props.weight || ""}
            onChange={(e) =>
              props.dispatch({
                field: "weight",
                value: parseFloat(e.target.value),
              })
            }
          />
        </Box>
        <Box width={1 / 3} px={2}>
          <Label htmlFor="diameter">{t("diameter")} (mm)</Label>
          <Input
            type="number"
            my={10}
            disabled={itemType === "child"}
            data-cy={dataCy + "-diameter"}
            value={props.diameter || ""}
            onChange={(e) =>
              props.dispatch({
                field: "diameter",
                value: parseFloat(e.target.value),
              })
            }
          />
        </Box>
      </Flex>
    </div>
  );
};

export default Dimensions;
