import ReactDOM from "react-dom";
import { Box, Button, Card, Flex, Heading } from "rebass";
import React, { useState } from "react";
import { Select } from "@rebass/forms";

const overlayRoot = document.getElementById("overlay-root");

function MassActionModal({ cancelClicked, okClicked, options, title }) {
  const [value, setValue] = useState(options[0]?.id || "");
  const [loading, setLoading] = useState(false);

  return ReactDOM.createPortal(
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: "rgba(0, 0, 0, 0.70)",
        height: "100vh",
        width: "100vw",
        zIndex: 999,
      }}
    >
      <Flex width={[4 / 5, 1 / 5]} height={"40%"}>
        <Card width={1}>
          <Flex
            height={"100%"}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Flex
              flexDirection={"column"}
              mx={[2]}
              height={"100%"}
              justifyContent={"center"}
            >
              <Heading
                as={"h4"}
                mb={[2, 4]}
                alignSelf={"center"}
                fontSize={[1, 2, 3]}
              >
                {title}
              </Heading>
              <Select
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                {options.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.name}
                  </option>
                ))}
              </Select>
            </Flex>

            <Box
              mx="auto"
              my="auto"
              textAlign="center"
              width={[1 / 2, 1 / 4]}
              style={{}}
            >
              {/*<Heading color="#9e9e9e">Please wait</Heading>*/}
              {loading && <img alt="loading" src="/loading.svg" />}
            </Box>

            <Flex justifyContent={"space-between"}>
              <Button
                variant={"outline"}
                ml={[2]}
                onClick={() => cancelClicked()}
              >
                Cancel
              </Button>
              <Button
                mr={[2]}
                onClick={async () => {
                  setLoading(true);
                  await okClicked(value);
                  setLoading(false);
                }}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Flex>,

    overlayRoot
  );
}

export default MassActionModal;
