import React, { useContext, useRef, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Box } from "rebass";
import { UPDATE_PRODUCT } from "../gql/mutations";
import {
  GET_PARENT_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_USERS,
} from "../gql/queries";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import ProductInputForm from "../components/ProductInputForm";
import ProductProcessForm from "../components/ProductProcessForm";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import AuthContext from "../components/AuthContext";
import { useTranslation } from "react-i18next";

let unblock = undefined;

const ProductEdit = (props) => {
  const id = props.match.params.id;
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const isDirty = () => {
    return (
      (processFormRef.current && processFormRef.current.isDirty()) ||
      (inputFormRef.current && inputFormRef.current.isDirty())
    );
  };

  const inputFormRef = useRef();
  const processFormRef = useRef();

  const roles = user.roles;

  const canInput = roles.includes("admin") || roles.includes("dataEntry");
  const canEdit = roles.includes("admin") || roles.includes("contentEdit");

  const saveProcessCompleted = () => {
    if (!inputFormRef.current || !inputFormRef.current.isDirty()) {
      unblock();
      props.history.push(`/products`);
    }
  };

  const saveInputCompleted = () => {
    if (!processFormRef.current || !processFormRef.current.isDirty()) {
      unblock();
      props.history.push(`/products`);
    }
  };

  useEffect(() => {
    unblock = props.history.block(() => {
      if (isDirty()) {
        return "Are you sure you want to leave the page? There are unsaved changes";
      }
    });

    window.onbeforeunload = function (event) {
      if (isDirty()) {
        event.preventDefault();
      }
    };

    return () => {
      unblock();
      window.onbeforeunload = null;
    };
  }, [props.history]);

  let { loading: loadingProduct, data: productData } = useQuery(GET_PRODUCT, {
    variables: {
      id: props.match.params.id,
    },
  });

  let { loading: loadingUsers, data: usersData } = useQuery(GET_USERS);
  let { loading: loadingParentProducts, data: parentProductsData } = useQuery(
    GET_PARENT_PRODUCTS
  );

  const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: [
      { query: GET_PRODUCTS },
      { query: GET_PARENT_PRODUCTS },
      { query: GET_PRODUCT, variables: { id } },
    ],
    awaitRefetchQueries: true,
  });

  if (loadingProduct || loadingUsers || loadingParentProducts) {
    return null;
  }

  const product = { ...productData.product };

  if (product.parts && product.parts.length) {
    product.parts = product.parts.map((p) => {
      const { __typename, ...otherAttributes } = p;
      return otherAttributes;
    });
  }

  product.userId = product.user ? product.user.id : null;

  product.dimensions = { ...product.dimensions };
  delete product.dimensions.__typename;

  product.packageDimensions = {
    ...product.packageDimensions,
  };
  delete product.packageDimensions.__typename;

  return (
    <Box width={[1, 1, 1, 2 / 3]} mx="auto">
      <Tabs defaultIndex={canInput ? 0 : 1} forceRenderTabPanel={true}>
        <TabList>
          <Tab data-cy="input-tab" disabled={!canInput}>
            {t("input")}
          </Tab>
          <Tab data-cy="process-tab" disabled={!canEdit}>
            {t("processing")}
          </Tab>
        </TabList>
        <TabPanel>
          <Box width={[1, 1, 1, 1 / 2]} mx="auto">
            <ProductInputForm
              parentItems={parentProductsData.parentProducts}
              onSaveCompleted={saveInputCompleted}
              product={product}
              ref={inputFormRef}
              users={usersData.users}
              mutation={updateProduct}
              loading={loading}
            />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box width={[1, 1, 1]} mx="auto">
            <ProductProcessForm
              onSaveCompleted={saveProcessCompleted}
              ref={processFormRef}
              product={product}
            />
          </Box>
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default ProductEdit;
