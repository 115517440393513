import React, { useState } from "react";
import { Box, Button, Flex, Text } from "rebass";
import { Input, Label } from "@rebass/forms";
import styled from "styled-components";
import ComboBox from "./ComboBox";
import { FiXCircle, FiPlusCircle } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const items = [
  { value: "length" },
  { value: "width" },
  { value: "height" },
  { value: "weight" },
  { value: "diameter" },
];

const ErrorText = styled.span`
  margin-left: 10px;
  color: red;
`;

const PartForm = (props) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState(props.part.name || "");
  const [attributes, setAttributes] = useState(
    props.part.attributes
      ? Object.keys(props.part.attributes).map((k) => [
          k,
          props.part.attributes[k],
        ])
      : [["length", ""]]
  );

  const addAttribute = () => {
    const attrs = [...attributes];
    attrs.push(["", ""]);

    setAttributes(attrs);
  };

  const deleteAttribute = (index) => {
    const attrs = [...attributes];
    attrs.splice(index, 1);

    setAttributes(attrs);
  };

  const handleKeyChange = (key, index) => {
    const attrs = [...attributes];
    attrs[index][0] = key;

    setAttributes(attrs);
  };
  const handleValueChange = (value, index) => {
    const attrs = [...attributes];
    attrs[index][1] = value;

    setAttributes(attrs);
  };

  const validate = () => {
    let messages = [];

    if (!name) {
      messages.push("Name is required");
    }

    attributes.forEach((a, i) => {
      if (!a[0]) {
        messages.push("Attribute " + (i + 1) + " is empty");
      } else if (!a[1]) {
        messages.push("Attribute " + a[0] + " is empty");
      }
    });

    setErrors(messages);
    return messages.length === 0;
  };

  return (
    <Box width={1} mx={10} height="100%">
      <Flex height="100%" flexDirection="column">
        <Box mt="10%">
          {errors.length !== 0 && <ErrorText>{errors.join(". ")}</ErrorText>}
          <Label htmlFor="name">{t("name")} *</Label>
          <Input
            id="name"
            data-cy="part-name"
            name="name"
            my={10}
            value={name || ""}
            onChange={(e) => {
              return setName(e.target.value);
            }}
          />
        </Box>

        <Box flex={1}>
          <Text fontSize={[1, 2, 3]} fontWeight="bold" color="primary">
            {t("attributes")}
          </Text>
          <Box style={{ border: "1px solid" }} px={2} py={20}>
            <Flex sx={{ borderBottom: "2px solid" }} mb={10}>
              <Box width={4 / 8} px={2} textAlign="center">
                {t("name")}
              </Box>
              <Box width={3 / 8} px={2} textAlign="center">
                {t("value")}
              </Box>
              <Box width={1 / 8} px={2} />
            </Flex>

            {attributes.map(([k, v], index) => {
              return (
                <Flex mx={-2} my={1} key={index}>
                  <Box width={4 / 8} px={2}>
                    <ComboBox
                      items={items}
                      data-cy="attribute-name"
                      value={k}
                      onChange={(v) => {
                        handleKeyChange(v, index);
                      }}
                    />
                  </Box>
                  <Box width={3 / 8} px={2}>
                    <Input
                      value={v}
                      data-cy="attribute-value"
                      onChange={(e) => handleValueChange(e.target.value, index)}
                    />
                  </Box>
                  <Box width={1 / 8} px={2} mr={2}>
                    <Button
                      variant="outline"
                      sx={{ boxShadow: "none" }}
                      onClick={() => deleteAttribute(index)}
                    >
                      <FiXCircle color="red" />
                    </Button>
                  </Box>
                </Flex>
              );
            })}
            <Flex>
              <Box width={4 / 8} />
              <Box width={3 / 8} textAlign="right" mr={2}>
                <Button
                  data-cy="add-attribute"
                  mt={2}
                  variant="outline"
                  onClick={addAttribute}
                  sx={{ boxShadow: "none" }}
                >
                  <FiPlusCircle size={24} color="green" />
                </Button>
              </Box>
              <Box width={1 / 8} />
            </Flex>
          </Box>
        </Box>

        <Box mb="10%" mt={2}>
          <Flex>
            <Button
              data-cy="part-done"
              onClick={() => {
                if (validate()) {
                  const attrs = attributes.reduce((o, [k, v]) => {
                    o[k] = v;
                    return o;
                  }, {});
                  props.onEditFinished({
                    name: name,
                    attributes: attrs,
                  });
                }
              }}
            >
              OK
            </Button>
            <Box flex={1} />

            <Button variant="outline" onClick={props.onCancel}>
              {t("cancel")}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default PartForm;
