import { Box, Flex, Link, Text } from "rebass";
import React, { useContext } from "react";
import AuthContext from "./AuthContext";
import { Link as ReactLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Header = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <Flex px={2} alignItems="center">
      <Text p={2} mr={10} fontWeight="bold">
        DIGITIZE
      </Text>{" "}
      {!!user.roles.length && (
        <Link variant="nav" to="/products" data-cy="products" as={ReactLink}>
          {t("products")}
        </Link>
      )}
      {user.roles.includes("admin") && (
        <Link variant="nav" to="/users" data-cy="users" as={ReactLink}>
          {t("users")}
        </Link>
      )}
      <Box mx="auto" />
      <Link variant="nav" to="/profile" as={ReactLink}>
        {t("profile")}
      </Link>
    </Flex>
  );
};

export default Header;
