import React from "react";
import { Link } from "rebass";

const FileUpload = (props) => {
  return (
    <div className="custom-file-container" data-upload-id={props.id}>
      <Link
        className="custom-file-container__image-clear"
        title="Clear Image"
        sx={{ display: "none" }}
      >
        &times;
      </Link>
      <label className="custom-file-container__custom-file">
        Images
        <input
          type="file"
          data-cy={props["data-cy"]}
          className="custom-file-container__custom-file__custom-file-input"
          accept="*"
          multiple
          aria-label="Choose File"
        />
        <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
        <span className="custom-file-container__custom-file__custom-file-control" />
      </label>
      <div className="custom-file-container__image-preview" />
    </div>
  );
};

export default FileUpload;
