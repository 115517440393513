import React from "react";
import { Box, Button, Flex, Text } from "rebass";
import { FiDownload } from "react-icons/fi";
import FileViewer from "./FileViewer";
import { useTranslation } from "react-i18next";

const displayCodes = (product, t) => {
  let result = [];
  if (product.code) {
    result.push(t("code") + ": " + product.code);
  } else if (product.ean) {
    result.push("EAN: " + product.ean);
  } else if (product.externalId) {
    result.push("External Id: " + product.externalId);
  }
  return result;
};

const displayDimensions = (dimensions, t) => {
  let result = [];
  if (dimensions.length) {
    result.push(t("length") + ": " + dimensions.length + " mm");
  }
  if (dimensions.width) {
    result.push(t("width") + ": " + dimensions.width + " mm");
  }
  if (dimensions.height) {
    result.push(t("height") + ": " + dimensions.height + " mm");
  }
  if (dimensions.weight) {
    result.push(t("weight") + ": " + dimensions.weight + " gr");
  }
  if (dimensions.diameter) {
    result.push(t("diameter") + ": " + dimensions.diameter + " mm");
  }
  return result.join(", ");
};

const merge = (parent, child, overwrittenProperties) => {
  if (!overwrittenProperties) {
    return child;
  }

  const overwrittenValues = Object.keys(child)
    .filter((k) => Object.keys(overwrittenProperties).includes(k))
    .reduce((res, k) => {
      return { ...res, [k]: child[k] };
    }, {});

  return { ...parent, ...overwrittenValues };
};

const ProductInputView = (props) => {
  const product = props.product;
  const { t } = useTranslation();

  const color =
    product.type === "child" && !product.parent.overwrittenProperties.color
      ? product.parent.color
      : product.color;

  const dimensions =
    product.type === "child"
      ? merge(
          product.parent.dimensions || {},
          product.dimensions || {},
          (product.parent.overwrittenProperties || {}).dimensions
        )
      : product.dimensions;

  const packageDimensions =
    product.type === "child"
      ? product.parent.packageDimensions
      : product.packageDimensions;

  const note = product.type === "child" ? product.parent.note : product.note;

  const parts = product.type === "child" ? product.parent.parts : product.parts;

  return (
    <Box>
      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold">{t("short_name")}</Text>
      </Box>

      <Box my={10}>{product.shortName}</Box>

      <Flex alignItems="center" mb={10} mt="2em">
        <Box>
          <Text fontSize={[1, 1, 2]} fontWeight="bold">
            {t("attachments")}
          </Text>
        </Box>
        <Box flex={1}>
          {!!product.attachments.length && (
            <Button
              py={1}
              mx={2}
              onClick={() =>
                (window.location.href =
                  process.env.REACT_APP_API_URL +
                  "/download/" +
                  product.id +
                  "/attachments")
              }
            >
              <FiDownload color="white" title="Download all" />
            </Button>
          )}
        </Box>
      </Flex>

      <FileViewer
        type="attachments"
        mt={10}
        attachments={product.attachments}
        productId={product.id}
      />

      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold"> {t("codes")}</Text>
      </Box>

      {displayCodes(product, t)}

      {!!color && (
        <Box width={4 / 6} mt={10}>
          <Text fontWeight="bold"> {t("color")}</Text>
          {color}
        </Box>
      )}

      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold"> {t("product_dimensions")}</Text>
      </Box>
      {!!dimensions && displayDimensions(dimensions, t)}
      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold">{t("package_dimensions")}</Text>
      </Box>
      {!!packageDimensions && displayDimensions(packageDimensions, t)}

      {!!product.parts && !!product.parts.length && (
        <Text fontSize={[1, 1, 2]} fontWeight="bold" mt={20} color="primary">
          Parts
        </Text>
      )}

      {(parts || []).map((part, index) => (
        <Flex sx={{ borderBottom: "1px solid" }} key={index} my={2} mt={10}>
          <Box width={5 / 6} my={2}>
            <strong>{part.name}</strong>
            <Box>
              {part.attributes &&
                Object.keys(part.attributes)
                  .map((k) => k + ": " + part.attributes[k])
                  .join(", ")}
            </Box>
          </Box>
        </Flex>
      ))}

      <Box width={4 / 6} mt={10}>
        <Text fontWeight="bold">{t("notes")}</Text>
      </Box>

      <Box sx={{ whiteSpace: "pre-wrap" }} mb={4}>
        {note}
      </Box>
    </Box>
  );
};

export default ProductInputView;
