import { gql } from "@apollo/client";

const IS_LOGGED_IN = gql`
  {
    isLoggedIn @client
    roles @client
  }
`;

const GET_PRODUCTS = gql`
  query products($includeArchived: Boolean) {
    products(includeArchived: $includeArchived) {
      id
      shortName
      code
      status
      user {
        id
        email
      }
      type
      description
      createdAt
      updatedAt
    }
  }
`;

const GET_USERS = gql`
  query users {
    users {
      id
      email
      active
      roles
    }
  }
`;

const GET_PARENT_PRODUCTS = gql`
  query parentProducts {
    parentProducts {
      id
      shortName
      name
      note
      code
      color
      dimensions {
        length
        width
        height
        diameter
        weight
      }
      packageDimensions {
        length
        width
        height
        diameter
        weight
      }
      parts {
        name
        attributes
      }
      overwrittenProperties
    }
  }
`;

const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      active
      roles
    }
  }
`;

const GET_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      shortName
      name
      code
      color
      status
      images
      externalId
      ean
      parent {
        id
        shortName
        color
        overwrittenProperties
        description
        dimensions {
          length
          diameter
          height
          width
          weight
        }
        packageDimensions {
          length
          diameter
          height
          width
          weight
        }
        note
        parts {
          name
          attributes
        }
      }
      user {
        id
        email
      }
      note
      parts {
        name
        attributes
      }
      dimensions {
        length
        diameter
        height
        width
        weight
      }
      packageDimensions {
        length
        diameter
        height
        width
        weight
      }
      type
      overwrittenProperties
      description
      specifications
      attachments
      createdAt
      updatedAt
    }
  }
`;

export {
  IS_LOGGED_IN,
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_USERS,
  GET_USER,
  GET_PARENT_PRODUCTS,
};
