import React from "react";
import { useMutation } from "@apollo/client";
import { NEW_USER } from "../gql/mutations";
import { GET_USERS } from "../gql/queries";
import { Box, Text } from "rebass";
import UserForm from "../components/UserForm";

const NewUser = (props) => {
  const [createUser] = useMutation(NEW_USER, {
    refetchQueries: [{ query: GET_USERS }],
  });

  return (
    <Box
      width={[1, 1 / 4]}
      mx="auto"
      px={10}
      mt={50}
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Text fontSize={[1, 1, 2]} fontWeight="bold" color="primary">
        Create User
      </Text>
      <UserForm
        user={{ roles: [] }}
        onSave={(u) => {
          createUser({ variables: { ...u } }).then(
            props.history.push(`/users`)
          );
        }}
      />
    </Box>
  );
};

export default NewUser;
