import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../gql/mutations";
import { Box, Button, Text } from "rebass";
import { Input } from "@rebass/forms";
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import styled from "styled-components";
import AuthContext from "../components/AuthContext";

const ErrorText = styled.span`
  color: red;
`;

const Profile = (props) => {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");

  const [errors, setErrors] = useState([]);
  const { user } = useContext(AuthContext);

  const validate = () => {
    let messages = [];

    if (!password) {
      messages.push("Password is required");
    }

    if (password !== verifyPassword) {
      messages.push("Passwords don't match");
    }

    setErrors(messages);

    return !messages.length;
  };

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      props.history.push(`/products`);
    },
  });

  return (
    <Box
      width={[1, 1 / 4]}
      mx="auto"
      px={10}
      mt={50}
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Text fontSize={[3, 4, 5]} fontWeight="bold" color="primary">
        Change Password
      </Text>

      <Text fontSize={[1, 2, 3]} fontWeight="bold">
        {user.roles.join(",")}
      </Text>

      <ErrorText>{errors.join(". ")}</ErrorText>
      <Input
        id="password"
        name="password"
        type="password"
        placeholder="password *"
        my={10}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Input
        id="verify-password"
        name="verify-password"
        type="password"
        placeholder="verify password *"
        my={10}
        value={verifyPassword}
        onChange={(e) => setVerifyPassword(e.target.value)}
      />

      <Button
        mt={10}
        onClick={() => {
          if (validate()) {
            changePassword({
              variables: {
                id: user.id,
                password,
              },
            });
          }
        }}
      >
        Save
      </Button>
    </Box>
  );
};

export default Profile;
