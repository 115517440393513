import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import NewProduct from "./newproduct";
import NewUser from "./newuser";
import ProductEdit from "./ProductEdit";
import Product from "./Product";

import Home from "./home";
import Login from "./login";
import Products from "./products";
import Users from "./users";
import User from "./user";
import Profile from "./profile";
import AuthContext from "../components/AuthContext";

const Pages = () => {
  return (
    <div>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/products" component={Products} />
      <PrivateRoute exact path="/users" component={Users} />
      <Switch>
        <PrivateRoute exact path="/products/new" component={NewProduct} />
        <PrivateRoute path="/products/:id/edit" component={ProductEdit} />
        <PrivateRoute path="/products/:id" component={Product} />
      </Switch>
      <Switch>
        <PrivateRoute exact path="/users/new" component={NewUser} />
        <PrivateRoute exact path="/users/:id" component={User} />
      </Switch>
      <PrivateRoute exact path="/profile" component={Profile} />
      <Route path="/login" component={Login} />
    </div>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        !!user.roles.length ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default Pages;
