import React, { useEffect, useState } from "react";

import { GET_PRODUCTS } from "../gql/queries";
import { useQuery } from "@apollo/client";

import { Box } from "rebass";

import ProductTable from "../components/ProductTable";

const Products = (props) => {
  const [showArchivedProducts, setShowArchivedProducts] = useState(false);

  const { loading, data, refetch } = useQuery(GET_PRODUCTS, {
    variables: { includeArchived: showArchivedProducts },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch({ includeArchived: showArchivedProducts });
  }, [showArchivedProducts]);

  if (loading) {
    return "Loading ...";
  } else if (data && data.products) {
    return (
      <div>
        <Box width={[1, 1, 1, 3 / 4]} mx="auto">
          {!loading && (
            <ProductTable
              data={data.products}
              history={props.history}
              showArchivedProducts={showArchivedProducts}
              setShowArchivedProducts={setShowArchivedProducts}
            />
          )}
        </Box>
      </div>
    );
  }
};

export default Products;
