import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Button, Flex, Text } from "rebass";
import FileViewer from "./FileViewer";
import FileUpload from "./FileUpload";
import FileUploadWithPreview from "file-upload-with-preview";
import { Checkbox, Label } from "@rebass/forms";
import { Textarea } from "./Textarea.css";
import { useMutation } from "@apollo/client";
import { UPDATE_CONTENT_AND_IMAGES } from "../gql/mutations";
import { GET_PRODUCT, GET_PRODUCTS } from "../gql/queries";
import { FiDownload } from "react-icons/fi";
import ErrorText from "./ErrorText";
import Overlay from "./Overlay";
import ProductInputView from "./ProductInputView";
import { useTranslation } from "react-i18next";

let upload = null;
const ProductProcessForm = forwardRef((props, ref) => {
  const product = props.product;
  const id = product.id;
  const { t } = useTranslation();

  const [name, setName] = useState(product.name || "");
  const [ready, setReady] = useState(product.status === "in_review");
  const [specifications, setSpecifications] = useState(
    product.specifications || ""
  );
  const [description, setDescription] = useState(product.description || "");
  const [deletedImages, setDeletedImages] = useState([]);
  const [errors, setErrors] = useState([]);

  useImperativeHandle(ref, () => ({
    isDirty() {
      return (
        (props.product.name ?? "") !== name ||
        (props.product.description ?? "") !== description ||
        deletedImages.length
      );
    },
  }));

  const validate = () => {
    let messages = [];

    if (!name) {
      messages.push("Name is required");
    }

    setErrors(messages);

    return !messages.length;
  };

  const [updateContentAndImages, { loading }] = useMutation(
    UPDATE_CONTENT_AND_IMAGES,
    {
      refetchQueries: [
        { query: GET_PRODUCTS },
        { query: GET_PRODUCT, variables: { id } },
      ],
      awaitRefetchQueries: true,
    }
  );

  useEffect(() => {
    upload = new FileUploadWithPreview("upload-images", {
      text: {
        chooseFile: t("add_files"),
      },
    });
  }, []);
  return (
    <Flex flexWrap="wrap">
      {loading && <Overlay />}

      <Box width={[1, 1 / 2]} px={10} sx={{ borderRight: "1px dashed black" }}>
        <ProductInputView product={product} />
      </Box>

      <Box width={[1, 1, 1 / 2]} px={10}>
        <ErrorText>{errors.join(". ")}</ErrorText>
        <Label fontWeight="bold" htmlFor="name">
          {t("name")} *
        </Label>
        <Textarea
          data-cy="name"
          id="name"
          sx={{ resize: "none", height: "4em" }}
          name="name"
          my={10}
          value={name || ""}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />

        <Flex alignItems="center" mb={10}>
          <Box>
            <Text fontSize={[1, 1, 2]} fontWeight="bold">
              {t("processed_images")}
            </Text>
          </Box>
          <Box flex={1}>
            {!!product.images.length && (
              <Button
                py={1}
                mx={2}
                onClick={() =>
                  (window.location.href =
                    process.env.REACT_APP_API_URL +
                    "/download/" +
                    id +
                    "/images")
                }
              >
                <FiDownload color="white" title="Download all" />
              </Button>
            )}
          </Box>
        </Flex>
        {!!product.images && !!product.images.length && (
          <FileViewer
            type="images"
            data-cy="images"
            allowDelete={true}
            mt={10}
            attachments={product.images || []}
            productId={product.id}
            onDelete={(i) => {
              const deletedImgs = [...deletedImages];
              deletedImgs.push(i);

              setDeletedImages(deletedImgs);
            }}
          />
        )}

        <FileUpload data-cy="upload-images" id="upload-images" />

        <Box width={4 / 6}>
          <Text fontSize={[1, 1, 2]} fontWeight="bold">
            {t("description")}
          </Text>
        </Box>
        <Textarea
          id="description"
          disabled={product.type === "child"}
          data-cy="description"
          sx={{ height: "10em" }}
          placeholder={
            product.type === "child" ? t("complete_parent_description") : ""
          }
          name="description"
          my={20}
          value={
            product.type === "child"
              ? product.parent.description
              : description || ""
          }
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />

        <Box width={4 / 6}>
          <Text fontSize={[1, 1, 2]} fontWeight="bold">
            {t("specifications")}
          </Text>
        </Box>
        <Textarea
          id="specifications"
          data-cy="specifications"
          sx={{ height: "8em" }}
          name="specifications"
          value={specifications || ""}
          my={20}
          onChange={(e) => {
            setSpecifications(e.target.value);
          }}
        />

        {product.status !== "in_review" && (
          <Box>
            <Label>
              <Checkbox
                id="ready"
                data-cy="ready"
                onChange={() => {
                  setReady(!ready);
                }}
                checked={ready}
              />
              {t("ready_for_review")}
            </Label>
          </Box>
        )}

        <Button
          data-cy="save-product-process"
          mt={20}
          onClick={() => {
            if (validate()) {
              const variables = {
                id,
                name,
                specifications,
                images: upload.cachedFileArray,
                ready: !!ready,
                deletedImages,
              };

              if (product.type !== "child") {
                variables.description = description;
              }

              updateContentAndImages({
                variables,
              })
                .then(() => {
                  props.onSaveCompleted();
                })
                .catch((e) => setErrors([e.message]));
            }
          }}
        >
          {t("save")}
        </Button>
      </Box>
    </Flex>
  );
});

export default ProductProcessForm;
