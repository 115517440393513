import { Box, Button, Heading } from "rebass";
import { Input } from "@rebass/forms";
import React, { useContext, useEffect, useState } from "react";

import { getCurrentUser } from "../utils";

import AuthContext from "../components/AuthContext";

import { gql, useMutation } from "@apollo/client";
import ErrorText from "../components/ErrorText";
import { IS_LOGGED_IN } from "../gql/queries";

const SIGNIN_USER = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`;

const Login = (props) => {
  useEffect(() => {
    document.title = "Sign In - Digitize";
  });

  const [errors, setErrors] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [signIn] = useMutation(SIGNIN_USER, {
    refetchQueries: [{ query: IS_LOGGED_IN }],
    awaitRefetchQueries: true,
  });
  const { setUser } = useContext(AuthContext);
  return (
    <Box
      width={[1, 1, 1 / 2, 1 / 4]}
      minHeight={400}
      as="form"
      mx="auto"
      mt={50}
      sx={{ border: "1px solid" }}
      onSubmit={(e) => {
        e.preventDefault();
        signIn({
          variables: {
            email,
            password,
          },
        })
          .then(({ data }) => {
            sessionStorage.setItem("token", data.signIn);
            setUser(getCurrentUser());

            props.history.push("/products");
          })
          .catch((error) => setErrors([error]));
      }}
      textAlign="center"
    >
      <Heading fontSize={5} color="primary" textAlign="center" mt={40}>
        Digitize
      </Heading>

      <ErrorText>{errors.join(". ")}</ErrorText>

      <Box px={2} pb={3} width={1 / 2} mx="auto" mt={20}>
        <Input
          id="email"
          data-cy="email"
          placeholder="Email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Box px={2} width={1 / 2} mx="auto" mt={15}>
        <Input
          id="password"
          type="password"
          data-cy="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
      </Box>

      <Button variant="primary" mt={40} mx="auto" data-cy="login">
        Login
      </Button>
    </Box>
  );
};

export default Login;
