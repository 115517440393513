import React from "react";

import { Box, Flex } from "rebass";
import ProductInputView from "../components/ProductInputView";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT } from "../gql/queries";
import ProductProcessView from "../components/ProductProcessView";

const Product = (props) => {
  let { loading, data } = useQuery(GET_PRODUCT, {
    variables: {
      id: props.match.params.id,
    },
  });

  if (loading) {
    return null;
  }

  return (
    <Box width={[1, 1, 1, 2 / 3]} mx="auto">
      <Flex flexWrap="wrap">
        <Box
          width={[1, 1 / 2]}
          sx={{ borderRight: "1px dashed black" }}
          px={10}
        >
          <ProductInputView product={data.product} />
        </Box>
        <Box width={[1, 1 / 2]} px={10}>
          <ProductProcessView product={data.product} />
        </Box>
      </Flex>
    </Box>
  );
};

export default Product;
