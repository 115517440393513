import React from "react";
import styled from "styled-components";
import { Button, Link } from "rebass";
import { Link as ReactLink } from "react-router-dom";

import { useTable, useFlexLayout } from "react-table";
import { useMutation } from "@apollo/client";
import { DELETE_USER } from "../gql/mutations";
import { GET_USERS } from "../gql/queries";

import constants from "../constants";

const Styles = styled.div`
  padding: 1rem;
  ${"" /* These styles are suggested for the table fill all available space in its containing element */}
  display: block;
  ${"" /* These styles are required for a horizontaly scrollable table overflow */}
  overflow: auto;

  .table {
    border-spacing: 0;

    .thead {
      ${"" /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;
    }

    .table {
    }

    .tr {
      :first-child {
        .th {
          margin: 0 auto;
          border-left: 0px;
          border-right: 0px;
        }
      }
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid black;
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid black;

      ${"" /* In this example we use an absolutely position resizer,
       so this is required. */}
      position: relative;

      :first-child {
        border-left: 1px solid black;
      }

      :last-child {
        border-right: 1px solid black;
      }
    }
  }
`;
const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: align === "right" ? "flex-end" : "flex-start",
      alignItems: "flex-start",
      display: "flex",
    },
  },
];

function UserTable({ data, history }) {
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }],
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "User",
        columns: [
          {
            Header: "Email",
            accessor: "email",
            width: 200,
          },
          {
            Header: "Active",
            accessor: (u) => u.active.toString(),
            width: 40,
          },
          {
            Header: "Roles",
            accessor: (u) =>
              u.roles ? u.roles.map((r) => constants.roles[r]).join(", ") : "",
            width: 150,
          },
          {
            id: "actions",
            Header: "Actions",
            width: 100,
            Cell: ({ row }) => {
              let user = null;

              if (!data || !data.length) {
                return null;
              }
              user = data[row.id];
              return (
                <div>
                  <Link to={"/users/" + user.id} as={ReactLink}>
                    View
                  </Link>
                  {"  "}
                  <Link
                    href={"#"}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        deleteUser({ variables: { id: user.id } });
                      }
                    }}
                  >
                    Delete
                  </Link>
                </div>
              );
            },
          },
        ],
      },
    ],
    [data, deleteUser]
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      defaultColumn,
      data,
    },
    useFlexLayout
  );

  return (
    <Styles>
      <Button
        data-cy="add-user"
        variant="primary"
        mr={2}
        mb={2}
        mt={50}
        onClick={() => history.push("/users/new")}
      >
        Add
      </Button>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({})} className="tr">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => {
                  return (
                    <div {...cell.getCellProps(cellProps)} className="td">
                      {cell.render("Cell")}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
}

export default UserTable;
